import './App.css'
import logo from './logo.svg'

function App() {
  return (
  	<div className="app">
  		<div className="header" align="center">
  	        <input type="checkbox" id="hamburger"/>
  	        <label htmlFor="hamburger" className="hamburger">
  	            <span className="line"></span>
  	            <span className="line"></span>
  	            <span className="line"></span>
  	        </label>
  	        <img src={logo} alt="logo" className="logo"/>
  	    </div>
  	    <section className="main">
  	        <div className="main-circle"></div>
  	        
  	    </section>
	</div>
  );
}

export default App;
